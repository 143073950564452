import {
  AvvNodeGrammar,
  DeltaParser,
  generateTrackingChanges,
  getModifiedTrackingChanges
} from '@avvoka/editor'
import { HtmlParser, postProcessTrackingChanges } from '@avvoka/editor'
import { clone } from '@avvoka/shared'
import { NegoUtils } from './nego'

import ExportApi from '@api/Documents/ExportApi'
import { useUserStore } from '../../stores/generic/user.store'
import { handleCharacterAutoreplacement } from '~/features/utils'
import DraftsApi from '~/api/DraftsApi'
import { getCurrentDocumentId, useDocumentStore } from '~/stores/generic/document.store'
import { getActivePinia } from 'pinia'

export class CompareUtils {
  static setCompareBannerText(text: string) {
    const element = document.querySelector('p.compare')
    if (element) {
      element.textContent = text
    }
  }

  static limitHeight() {
    const editor = EditorFactory.get('compare').get()
    const mountPoint = editor.scroll.node.parentElement!.parentElement!
    const sidebar = mountPoint.querySelector('.avv-sidebar') as HTMLElement
    if (sidebar)
      sidebar.style.height = `${
        window.innerHeight - sidebar.getBoundingClientRect().top
      }px`
    const container = mountPoint.querySelector('.avv-container') as HTMLElement
    if (container)
      container.style.height = `${
        window.innerHeight - container.getBoundingClientRect().top
      }px`
    const wrapper = mountPoint.querySelector(
      '.avv-container-wrap'
    ) as HTMLElement
    if (wrapper)
      wrapper.style.height = `${
        window.innerHeight - wrapper.getBoundingClientRect().top
      }px`
  }

  static hideCompareEditor() {
    const editors = Array.from(
      document.querySelectorAll<HTMLElement>('.avv-editor')
    )
    editors.forEach((editor) => {
      const wrapper = editor.parentElement as HTMLElement
      const container = editor.querySelector(
        '.avv-container-wrap'
      ) as HTMLElement
      const childContainer = container.querySelector(
        '.avv-container'
      ) as HTMLElement
      const sidebar = editor.querySelector('.avv-sidebar') as HTMLElement
      wrapper.classList.remove('multiple-editors')
      editor.classList.remove('multiple-editors')
      if (editor.id === 'compare') editor.style.display = 'none'
      if (editor.id === 'editor') {
        sidebar.style.display = 'flex'
        editor.style.border = 'none'
      }
      container.style.gridTemplateColumns = '804px 400px'
      childContainer.style.width = ''
      if (sidebar) sidebar.style.position = ''
    })
    CompareUtils.emit()
  }

  static handleUI() {
    const editors = Array.from(
      document.querySelectorAll<HTMLElement>('.avv-editor')
    )
    editors.forEach((editor) => {
      const wrapper = editor.parentElement as HTMLElement
      const container = editor.querySelector(
        '.avv-container-wrap'
      ) as HTMLElement
      const childContainer = container.querySelector(
        '.avv-container'
      ) as HTMLElement
      const sidebar = editor.querySelector('.avv-sidebar') as HTMLElement
      const tabContent = document.querySelector(
        '.tab-content.p-top-0'
      ) as HTMLElement
      wrapper.classList.add('multiple-editors')
      editor.classList.add('multiple-editors')
      if (editor.id === 'compare') {
        editor.style.display = 'block'
        editor.style.border = ''
        if (sidebar) {
          container.style.gridTemplateColumns = '1fr 400px'
          sidebar.style.display = 'flex'
        }
      }
      if (editor.id === 'editor') {
        // sidebar.style.display = 'none'

        editor.style.border = ''
        editor.style.width = ''
        container.style.gridTemplateColumns = '1fr 400px'
      }
      childContainer.style.width = '100%'
      if (sidebar) sidebar.style.position = 'unset'
      if (tabContent) tabContent.classList.remove('p-top-0')
    })
  }

  static async createCompareEditor(callback?: (editorId: string) => void) {
    return new Promise<void>((resolve) => {
      EditorFactory.create({
        id: 'compare',
        bounds: '#compare',
        mode: 'document',
        readOnly: true,
        loadComments: true,
        rtl: false,
        onCreate(ed) {
          CompareUtils.handleUI()
          if (typeof callback === 'function') callback(ed)
          resolve()
        }
      })

      EditorFactory.get('compare')
        .get()
        .onReady.subscribe(({ ready }) => {
          if (ready) CompareUtils.limitHeight()
        })
      window.addEventListener('resize', CompareUtils.limitHeight)
    })
  }

  static getOptions(draftId: number) {
    return {
      type: 'post',
      url: `/load/${draftId}`
    }
  }

  static async getTemplateBody(
    documentId: number
  ): Promise<{ comments: Array<Backend.Models.Comment>; body: string }> {
    return {
      comments: [],
      body: await ExportApi.create({
        data: { document_ids: documentId, formats: 'document_html_draft' }
      })
    }
  }

  static async getBody(
    draftId: number
  ): Promise<{ comments: Array<Backend.Models.Comment>; body: string }> {
    const options = CompareUtils.getOptions(draftId)
    return await $.ajax(options)
  }

  static async handleComparing(html1: string, html2: string, text: string) {
    // Create compare editor if it doesn't exist
    if (!EditorFactory.exists('compare')) {
      await CompareUtils.createCompareEditor()
    }

    const userStore = useUserStore()
    const participant = clone(
      AvvStore.state.participants.find(
        (participant) => participant.user_id === userStore.id
      )
    )

    const originalNode = HtmlParser.parse(html1)
    const updatedNode = HtmlParser.parse(html2)

    AvvNodeGrammar.applyGrammar(originalNode)
    AvvNodeGrammar.applyGrammar(updatedNode)
    AvvNodeGrammar.applyInlineNormalization(originalNode, 'document')
    AvvNodeGrammar.applyInlineNormalization(updatedNode, 'document')

    const rejectedDocumentDelta = getModifiedTrackingChanges(
      originalNode.toDelta(),
      {
        rejectInsertedLine: true,
        rejectInsertedText: true,
        rejectDeletedLine: true,
        rejectDeletedText: true,
        rejectDeletedTcData: true,
        rejectInsertedTcData: true,
        bypassRejectingRepeaterIterationRestriction: true
      }
    )

    const acceptedUpdatedDocumentDelta = getModifiedTrackingChanges(
      updatedNode.toDelta(),
      {
        acceptInsertedLine: true,
        acceptInsertedText: true,
        acceptDeletedLine: true,
        acceptDeletedText: true,
        acceptDeletedTcData: true,
        acceptInsertedTcData: true
      }
    )

    const oldNode = DeltaParser.parse(rejectedDocumentDelta, 'document')
    const newNode = DeltaParser.parse(acceptedUpdatedDocumentDelta, 'document')

    AvvNodeGrammar.applyInlineNormalization(oldNode, 'document')
    AvvNodeGrammar.applyInlineNormalization(newNode, 'document')

    const trackingChangesUpdateDelta = generateTrackingChanges(
      oldNode,
      newNode,
      {
        id: participant?.id ?? -1,
        party: participant?.party_type ?? 'unknown'
      }
    )

    let composedDelta = acceptedUpdatedDocumentDelta.compose(
      trackingChangesUpdateDelta
    )

    postProcessTrackingChanges({
      composedDelta,
      beforeDelta: rejectedDocumentDelta,
      afterDelta: acceptedUpdatedDocumentDelta
    })

    const node = DeltaParser.parse(composedDelta, 'document')
    AvvNodeGrammar.applyGrammar(node)
    AvvNodeGrammar.applyInlineNormalization(node, 'document')

    const hidingNewNode = node.clone()
    AvvNodeGrammar.applyNegoGrammar(hidingNewNode)
    const hideDelta = node.toDelta().diffAttributes(hidingNewNode.toDelta())!

    composedDelta = composedDelta.compose(hideDelta)

    EditorFactory.get('compare').ifPresent((editor) => {
      editor.load(composedDelta)
    })
    CompareUtils.handleUI()
    CompareUtils.setCompareBannerText(text)
  }

  static loadComments(comments: Array<Backend.Models.Comment.Parsed>) {
    comments.forEach((comment) => {
      comment.message.forEach((entry) => {
        const RE = /<user-mention[^>]*participant="(\d+)"[^>]*>/g
        const mentions = []
        for (const match of entry.text.matchAll(RE)) {
          mentions.push(parseInt(match[1]))
        }
        entry.mentions = mentions
        entry.author = parseInt(entry.author as any)
      })
    })

    EditorFactory.getEntry('compare').get().bridge!.overrideComments = comments
  }

  static async displayVersion(draft: Backend.Models.Draft) {
    if (!draft) return false
    const { body, comments } = await CompareUtils.getBody(draft.id)
    const versionName =
      draft.name ??
      `${window.localizeText('document.compare.viewing')} ${draft.version}`
    const result = await CompareUtils.handleVersion(body, versionName)
    CompareUtils.loadComments(comments)
    return result
  }

  static async handleVersion(html: string, text: string) {
    if (!EditorFactory.exists('compare'))
      await CompareUtils.createCompareEditor()
    const compareEditor = EditorFactory.get('compare').get()
    compareEditor.load(html)
    CompareUtils.handleUI()
    CompareUtils.setCompareBannerText(text)
  }

  static getVersion(draftId: number): 'Cannot load version' | number {
    const element = document.querySelector(
      `#versions > a[data-id="${draftId}"] > b`
    )
    if (!element) return 'Cannot load version'
    return +(element.textContent ?? 0)
  }

  static async saveVersion(
    reason: 'save' | 'publish' | 'uploaded' | 'docx_nego_export' | 'docx_nego_import' = 'save'
  ): Promise<void> | never {
    await handleCharacterAutoreplacement()

    if (!EditorFactory.exists('draft')) {
      window.location.reload()
      return
    }

    return DraftsApi.save({
      data: {
        document: EditorFactory.get('draft').get().negotiation.id,
        reason
      }
    }).then(async () => {
      const documentStore = useDocumentStore(getActivePinia())
      await documentStore.hydrateById(getCurrentDocumentId(), ['id', 'drafts'], true)
    })
  }

  static loadCurrentVersion() {
    window.avv_dialog({
      confirmMessage:
        'Are you sure you want to load this version? This will replace the current content in the editor.',
      confirmCallback: async (value) => {
        if (value) {
          const editor = EditorFactory.get('draft').get()
          let success = true
          if (NegoUtils.isUnlocked(editor))
            success = await NegoUtils.unlock(editor)
          if (!NegoUtils.isLockedToMe(editor) || !success) return
          const compareEditor = EditorFactory.get('compare').get()
          EditorFactory.main.load(compareEditor.getDelta())
          await EditorFactory.main.negotiation.asyncSubmitDeltaChange()
          await CompareUtils.saveVersion()
          window.location.reload()
        }
      }
    })
  }

  static onCloseListeners: Array<() => void> = []
  static onClose(cb: () => void) {
    CompareUtils.onCloseListeners.push(cb)
  }

  static emit() {
    CompareUtils.onCloseListeners.forEach((cb) => cb())
  }
}
